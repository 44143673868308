import VueApexCharts from 'vue-apexcharts'
export default {
  name: 'StackedBar',
  props: ['pressedKeysSections', 'sectionNames'],
  components: { apexchart: VueApexCharts },
  data () {
    return {
      ready: false,
      series: [],
      barHeightMultiplier: 80,
      height: 0,
      chartOptions: {},
    }
  },
  methods: {
    setLegendTooltip (chartData) {
      chartData.forEach(function (cd, i) {
        const idx = i + 1
        const id = '.apexcharts-legend-series[rel="' + idx + '"]'
        const tooltipHtml = '<div class="legend-tooltip-' + idx + '">' + cd.description + '</div>'
        const tooltipCss =
          '<style type="text/css">' +
            '.legend-tooltip-' + idx + '{' +
                'display: none;' +
                'position: absolute;' +
                'left: 25%;' +
                'bottom: 40%;' +
                'border-radius: 2px;' +
                'background-color: #2a2a2ab8;' +
                'color: #FFF;' +
                'padding: 1px 4px 0px 4px;' +
                'z-index: 1500;' +
                'font-size: 12px;' +
                'text-overflow: ellipsis;' +
                'white-space: nowrap;' +
                'overflow: hidden;' +
             '}' +
             '.apexcharts-legend-series[rel="' + idx + '"] {' +
                  'position: relative;' +
             '}' +
             '.apexcharts-legend-series[rel="' + idx + '"]:not(.apexcharts-inactive-legend):hover > .legend-tooltip-' + idx + '{' +
                  'display: block' +
             '}' +
          '</style>'
          document.querySelectorAll(id)[0].innerHTML += (tooltipCss + tooltipHtml)
      })
      document.getElementsByClassName('apexcharts-legend')[0].classList.add('apexcharts-legend-default-overflow')
    },
    setChartOptions () {
      const that = this
      this.chartOptions = {
        chart: {
          type: 'bar',
          height: 50,
          stacked: true,
          stackType: '100%',
          events: {
            updated: (chartContext, config) => {
              that.setLegendTooltip(config.config.series)
           },
           mounted: function (chartContext, config) {
              that.setLegendTooltip(config.config.series)
           },
          },
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900,
                },
              },
            },
          },
        },
        dataLabels: {
          formatter: function (val) {
            const percentage = that.$options.filters.shortDecimal(val, 2)
              return percentage === 0 ? '' : percentage + '%'
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        title: {
          text: undefined,
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40,
        },
      }
    },

    formattPressedKeysData () {
      this.setChartOptions()
      const keys = [...Array(10).keys(), ...[0, -1]]
      keys.shift()

      const emptyArray = [...Array(this.pressedKeysSections.length).keys()]
      const keysObject = []

      keys.forEach((key, index) => {
        keysObject.push(
          {
            name: this.$t('Tecla') + ' ' + (key === -1 ? this.$t('equivocada') : key),
            key: key,
            data: emptyArray.map(() => { return 0 }),
            index: index,
          },
        )
      })

      let usedKeys = []
      console.log('pressedKeysSections', this.pressedKeysSections)
      this.pressedKeysSections.forEach((section, sectionIndex) => {
        section.section.forEach((action) => {
          usedKeys.push(action.key)
          const keyObjectIndex = this.getIndexFromkeyObjectsByKey(keysObject, action.key)
          keysObject[keyObjectIndex].data[sectionIndex] = action.percentage
          keysObject[keyObjectIndex].description = action.description
        })

        usedKeys.push(-1)
        const keyObjectIndex = this.getIndexFromkeyObjectsByKey(keysObject, -1)
        keysObject[keyObjectIndex].data[sectionIndex] = this.getSectionPercentage(section)
        keysObject[keyObjectIndex].description = this.$t('Tecla equivocada').toString()
      })

      usedKeys = [...new Set(usedKeys)]

      const finalKeysObject = keysObject.filter((element) => {
        return usedKeys.includes(element.key)
      })

      this.series = finalKeysObject
      this.chartOptions.xaxis.categories = this.sectionNames
      this.height = this.barHeightMultiplier * this.sectionNames.length
      this.chartOptions.height = this.height

      this.ready = true
    },

    getSectionPercentage (section) {
      const totalKeyPreesed = section.section[0].totalSection
      const wrongKeyPress = this.getSectionWrongKeyPressedTotal(section)
      const percentage = ((wrongKeyPress / totalKeyPreesed) * 100)
      return this.$options.filters.shortDecimal(percentage, 2)
    },

    getSectionWrongKeyPressedTotal (section) {
      return section.section[0].totalSection - section.section.reduce((accumulator, currentValue) => { return accumulator + currentValue.keyPressTotal }, 0)
    },

    getIndexFromkeyObjectsByKey (keysObject, key) {
      const foundKeyObjects = keysObject.filter((element) => { return element.key === key })
      return foundKeyObjects[0].index
    },
  },
  created () {
    this.formattPressedKeysData()
  },
}
