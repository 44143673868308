import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import getSymbolFromCurrency from 'currency-symbol-map'
import getEnv from '@/util/env'
import EventBus from '@/util/EventBus'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import { ExportForm } from '@/classes/ExportForm'
import { ImpactCost } from '@/classes/stats/ImpactCost.js'
import BrowserCache from '@/services/browser-cache.js'
import VoiceStatsService from '@/services/voice-stats.service'
import DateRange from '@/components/Graphs/DateRange/DateRange.vue'
import ImpactCostGeneral from '@/components/Graphs/ImpactCostGeneral/ImpactCostGeneral.vue'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import ConfirmCustomFieldsModal from '@/components/Stats/ConfirmCustomFieldsModal/ConfirmCustomFieldsModal.vue'
import ShareButton from '@/components/Stats/ShareButton/ShareButton.vue'
import ShareModal from '@/components/Stats/ShareModal/ShareModal.vue'
import UnsubscribedVoice from '@/components/Stats/Voice/UnsubscribedVoice.vue'
import DeliveredVoice from '@/components/Stats/Voice/DeliveredVoice.vue'
import { Colors } from '@/classes/colors/Colors'
import StatsMixin from '../Mixins/StatsMixin'
import StatCard from '@/components/Stats/Card/StatCard.vue'
import { VoiceGraphicData } from '@/classes/stats/VoiceGraphicData'
import { VoiceCalculatedData } from '@/classes/stats/Calculations/Voice/VoiceCalculatedData'
import RadialBarWithLegend from '@/components/Stats/RadialBar/RadialBarWithLegend.vue'
import RadialBar from '@/components/Stats/RadialBar/RadialBar.vue'
import StackedMultiple from '@/components/Stats/StackedColumn/StackedMultiple.vue'
import SimpleBar from '@/components/Stats/SimpleBar/SimpleBar.vue'
import StackedColumn from '@/components/Stats/StackedColumn/StackedColumn.vue'
import { SendingStatuses } from '@/models/SendingStatuses'
import StackedBar from '@/views/Pages/Stats/Voice/Charts/StackedBar.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'CampaignVoiceInteractiveStats',
  components: {
    HeaderTopDashboard,
    StatsTitle,
    DateRange,
    apexchart: VueApexCharts,
    ImpactCostGeneral,
    ConfirmCustomFieldsModal,
    ShareButton,
    ShareModal,
    UnsubscribedVoice,
    DeliveredVoice,
    StatCard,
    RadialBarWithLegend,
    StackedMultiple,
    SimpleBar,
    StackedColumn,
    RadialBar,
    StackedBar,
  },
  props: {
    campaignId: {
      type: Number,
      required: true,
    },
  },
  mixins: [StatsMixin],
  data: function () {
    return {
      exportForm: new ExportForm(),
      globalControl: new GlobalControl(),
      loading: true,
      options: {},
      content: [],
      searchTerm: '',
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      totalCampaigns: 0,
      data: [],
      additional: undefined,
      globalTotalSms: 0,
      currencySymbol: getSymbolFromCurrency(BrowserCache.getJson('user').currencyCode),
      ready: true,
      debouncedInput: '',
      timeout: null,
      impactCost: new ImpactCost(),
      campaign: {},
      channel: {},
      selectedHeaders: [],
      sendingDetails: {
        initDate: '',
        endDate: moment().format('YYYY-MM-DD'),
        customFields: false,
        columns: [],
        events: [],
        sendingId: null,
      },
      arrStatuses: Colors.statusesColors,
      campaginDataSource: {},
      readyStats: false,
      pressedKeysData: {},
      newPressedKeysData: [],
      loadedPressedKeys: false,
      tags: '',
      voiceCalculatedData: new VoiceCalculatedData(),
      cards: [],
      deliveredRatioGraphic: null,
      pulseRatioBarChart: null,
      newPulseRatioBarChart: null,
      redGraphicData: null,
      deliveredGraphicData: null,
      user: JSON.parse(localStorage.getItem('user')),
      pressedKeysSections: [],
      showNewInteractionStats: true,
      deadlineNewStatisticsDate: '2023-01-02 11:00:00',
      sectionNames: [],
    }
  },
  computed: {
    hasMenu () {
      return this.channel?.menu?.menuOptions?.length > 0
    },
    audioUrl () {
      const url = new URL(this.channel.menu.url)
      url.searchParams.set('token', this.user.token)
      return url.toString()
    },
    locale () {
      return this.$i18n.locale
    },
    headers () {
      return [
        { text: this.$t('ID Envío'), align: 'start', value: 'sending_id' },
        { text: this.$t('Fecha'), align: 'start', value: 'sending_date' },
        { text: this.$t('Llamadas'), align: 'start', value: 'fixed_sent_total' },
        { text: this.$t('Atendidas'), align: 'start', value: 'delivered_total' },
        { text: this.$t('No Atendidas'), align: 'start', value: 'fixed_undelivered_total' },
        { text: this.$t('Reintentos'), align: 'start', value: 'fixed_calls_retries' },
        { text: this.$t('Bajas'), align: 'start', value: 'unsubscribed_total' },
        { text: this.$t('Acciones'), align: 'start', value: 'actions', sortable: false, width: '14%' },
      ]
    },
    selectHeaders () {
      return [
        { text: this.$t('ID Envío'), align: 'start', value: 'sending_id' },
        { text: this.$t('Fecha'), align: 'start', value: 'sending_date' },
        { text: this.$t('Llamadas'), align: 'start', value: 'fixed_sent_total' },
        { text: this.$t('Atendidas'), align: 'start', value: 'delivered_total' },
        { text: this.$t('No Atendidas'), align: 'start', value: 'fixed_undelivered_total' },
        { text: this.$t('Reintentos'), align: 'start', value: 'fixed_calls_retries' },
        { text: this.$t('Bajas'), align: 'start', value: 'unsubscribed_total' },
        { text: this.$t('Acciones'), align: 'start', value: 'actions' },
      ]
    },

    pressedKeysHeaders () {
      const headers = [
        { text: this.$t('Sección'), align: 'start', value: 'sectionName' },
        { text: this.$t('Tecla'), align: 'start', value: 'key' },
        { text: this.$t('Descripción'), align: 'start', value: 'key' },
        { text: this.$t('Acción'), align: 'start', value: 'action' },
        { text: this.$t('Pulsaciones'), align: 'start', value: 'pressed_number' },
        { text: this.$t('Porcentaje'), align: 'start', value: 'pressed_percentage' },
        { text: '', align: 'start', value: 'actions' },
      ]

      if (!this.showNewInteractionStats) {
        headers.splice(0, 1)
        headers.splice(1, 1)
        headers.splice(3, 1)
      }
      return headers
    },
    showHeaders () {
      return this.headers.filter(h => this.selectedHeaders.map(sh => sh.value).includes(h.value))
    },
    params (nv) {
      return {
        ...this.options,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
  },
  methods: {

    downloadFullContactInteractionsCsv () {
      const user = JSON.parse(localStorage.getItem('user'))
      const url = getEnv('VUE_APP_API_URL') + 'api/stats/voice/full-csv-contact-interaction/' + this.campaign.campaignId + '?token=' + user.token
      window.location.href = url
    },

    downloadContactInteractionRoutesCsv () {
      const user = JSON.parse(localStorage.getItem('user'))
      const url = getEnv('VUE_APP_API_URL') + 'api/stats/voice/csv-contact-interaction-routes/' + this.campaign.campaignId + '?token=' + user.token
      window.location.href = url
    },


    setGraphicsData () {
      const voiceGraphicData = new VoiceGraphicData(this.voiceCalculatedData, this)
      this.cards = voiceGraphicData.getCardsData()
      this.deliveredRatioGraphic = voiceGraphicData.getDeliveredRatioGraphicGraphicData()
      this.pulseRatioBarChart = voiceGraphicData.getPulseRatioBarChartGraphicData(this.pressedKeysData)
      this.newPulseRatioBarChart = voiceGraphicData.getNewPulseRatioBarChartGraphicData(this.newPressedKeysData)
      this.redGraphicData = voiceGraphicData.getRedGraphicData()
      this.deliveredGraphicData = voiceGraphicData.getDeliveredGraphicData()
    },

    getAllPercentages (optionsObject) {
      const result = []
      for (const key in optionsObject) {
          const element = optionsObject[key]
          result.push(element.percentage)
      }
      return result
    },

    selectedColumn (column) {
      let exist = false
      for (let i = 0; i < this.selectedHeaders.length; i = i + 1) {
        if (!exist) {
          exist = this.selectedHeaders[i].value === column
        }
      }
      return exist
    },
    getItemTotalsParsedObject (item) {
      return {
        7: parseInt(item.fixed_sent_total),
        1: parseInt(item.delivered_total),
        94: parseInt(item.fixed_undelivered_total),
        11: parseInt(item.expired_total),
        4: parseInt(item.unsubscribed_total),
      }
    },

    showConfirmCustomFields (actionMode, item) {
      const totalEventValues = this.getItemTotalsParsedObject(item)
      EventBus.$emit('showConfirmCustomFields', actionMode, totalEventValues)
    },

    getColumns () {
      const columns = []
      this.headers.forEach(function (column) {
        columns.push(column.value)
      })
      return columns
    },
    setExportData () {
      this.exportForm.setFromParamsAndColums(this.getParams(), this.getColumns())
    },
    exportAction (urlSuffix) {
      this.setExportData()
      const url = getEnv('VUE_APP_API_URL') + 'api/stats/voice/campaign-export-' + urlSuffix + '?' + this.exportForm.getUrlParams()
      window.location.href = url
    },

    exportCsv () {
      this.exportAction('csv')
    },

    exportPdf () {
      this.exportAction('pdf')
    },

    sumReduceByKey (items, key) {
      return items.reduce(
        function (a, b) {
          return a + parseInt(b[key])
        }, 0)
    },

    sumReduceDecimalByKey (items, key) {
      return items.reduce(
        function (a, b) {
          return a + parseFloat(b[key])
        }, 0).toFixed(5)
    },

    calculateFixedTotalCalls (data) {
      const fixedSentTotal =
      parseInt(data.delivered_total) +
      parseInt(data.rejected_total) +
      parseInt(data.expired_total) +
      parseInt(data.undelivered_total) +
      parseInt(data.unsubscribed_total)

      let total = fixedSentTotal || 0

      if (total < 0) {
        total = 0
      }
      return total || 0
    },

    isApi (data) {
      return data.status_id === SendingStatuses.API
    },

    calculateTotalCalls (data) {
      // const result = this.isApi(data) ? data.total_processed : data.total_recipients
      const result = data.total_processed
      return result || 0
    },

    calculateUndeliveredTotal (data) {
      const deliveredTotal = data.delivered_total ? data.delivered_total : 0
      return this.calculateTotalCalls(data) - deliveredTotal
    },

    calculateCallRetries (data) {
      let fixedTotalCalls = this.calculateFixedTotalCalls(data)
      fixedTotalCalls = fixedTotalCalls === 0 ? this.calculateTotalCalls(data) : fixedTotalCalls
      const result = fixedTotalCalls - this.calculateTotalCalls(data)
      return result < 0 ? 0 : result
    },

    setTableData (data) {
      const campaignIds = []
      data.forEach(data => {
        data.fixed_sent_total = this.calculateTotalCalls(data)
        data.fixed_undelivered_total = this.calculateUndeliveredTotal(data)
        data.fixed_calls_retries = this.calculateCallRetries(data)
        campaignIds.push(data.campaign_id)
      })
      this.content = data

      const totalSms = this.additional[1]
      if (totalSms) {
        this.globalTotalSms = totalSms.cost != null ? parseFloat(totalSms.cost) : parseFloat('0.000')
      }
    },

    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },

    // eslint-disable-next-line max-lines-per-function
    setPieChart (totals) {
      totals.fixed_sent_total = this.calculateTotalCalls(totals)
      totals.fixed_undelivered_total = this.calculateUndeliveredTotal(totals)
      totals.delivered_total = parseInt(totals.delivered_total)
      this.resetReady()
    },

    getParams () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        campaignId: this.campaignId,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },
    getDataFromApi () {
      EventBus.$emit('showLoading', true)
      const params = this.getParams()

      this.content = []
      this.loading = true
      VoiceStatsService.getCampaignDatatable(params)
        .then(
          (response) => {
            this.readyStats = false
            this.campaginDataSource = response
            this.additional = JSON.parse(JSON.stringify(response.additional))
            this.data = JSON.parse(JSON.stringify(response.data))
            this.setTableData(response.data)
            this.itemsPerPage = parseInt(response.per_page)
            this.page = response.current_page
            this.totalCampaigns = response.total
            this.setPieChart(this.additional[0])

            if (this.data.length) {
              this.sendingDetails.initDate = moment(this.data[0].sending_date).format('YYYY-MM-DD')
              this.sendingDetails.sendingId = this.data[0].sending_id
            }
            setTimeout(() => {
              this.voiceCalculatedData.calculate(this.campaginDataSource)
              this.setGraphicsData()
              this.readyStats = true
            })
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
    },
    statusColor (campaign) {
      return this.arrStatuses[campaign.status_name]?.color
    },

    downloadCsvPhonesByKey (key) {
      const user = JSON.parse(localStorage.getItem('user'))
      const url = getEnv('VUE_APP_API_URL') + 'api/stats/voice/export-contact-phones-form-key-csv/' + this.channel.id + '/' + key + '?token=' + user.token
      window.location.href = url
    },

    getContactPhonesByPressedKey (key, channel) {
      VoiceStatsService.getContactPhonesByPressedKey(this.channel.id, key)
      .then(
        (response) => {
          localStorage.setItem('contact-phones-new-campaign', JSON.stringify(response))
          switch (channel) {
            case 'sms':
              EventBus.$emit('newSmsCampaign')
            break
            case 'voice':
              this.$router.push('/campaign/voice/create')
            break
          }
        },
        () => { },
      )
    },

    getSectionPercentage (section) {
      const totalKeyPressed = section.section[0].totalSection
      const wrongKeyPress = this.getSectionWrongKeyPressedTotal(section)
      const percentage = (totalKeyPressed ? (wrongKeyPress / totalKeyPressed) * 100 : 0)
      return this.$options.filters.shortDecimal(percentage, 2)
    },

    getSectionWrongKeyPressedTotal (section) {
      return section.section[0].totalSection - section.section.reduce((accumulator, currentValue) => { return accumulator + currentValue.keyPressTotal }, 0)
    },

    setPressedKeysDataAsSections () {
      let sections = []
      this.newPressedKeysData.forEach(element => {
        sections.push(element.sectionName)
      })

      sections = [...new Set(sections)]

      const sectionsData = []

      sections.forEach(sectionName => {
        const section = []

        this.newPressedKeysData.forEach(element => {
          if (element.sectionName === sectionName) {
            element.percentage = this.$options.filters.shortDecimal(element.percentage, 2)
            section.push(element)
          }
        })
        sectionsData.push({ sectionName: sectionName, section: section })
      })
      this.pressedKeysSections = sectionsData
      this.sectionNames = sections
    },

    checkUseNewStatistics () {
      const campaignTime = new Date(this.campaign.created_at).getTime()
      const deadLineTime = new Date(this.deadlineNewStatisticsDate).getTime()

      if (campaignTime > deadLineTime) {
        this.showNewInteractionStats = true
      } else {
        this.showNewInteractionStats = false
      }
    },
  },
  watch: {
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
    locale () {
      if (!this.$isSectionsBlockeds('costs')) {
        this.headers.splice(7, 0, {
          text: this.$t('Coste'),
          align: 'start',
          value: 'delivered_cost',
          class: 'w-6',
        })
      }
      this.setGraphicsData()
    },
    searchTerm () {
      this.options.page = 1
      this.getDataFromApi()
    },
  },
  created () {
    EventBus.$emit('showLoading', true)
    this.selectedHeaders = this.headers
    // this.campaignId = this.$route.params.id

    VoiceStatsService.getCampaignData(this.campaignId)
    .then(
      (response) => {
        this.campaign = response.campaign
        response.channel.menu = JSON.parse(response.channel.menu)
        this.channel = response.channel
        this.pressedKeysData = response.pressedKeysData
        this.newPressedKeysData = response.newPressedKeysData
        this.setPressedKeysDataAsSections()
        this.loadedPressedKeys = true
        this.tags = response.tags
        this.checkUseNewStatistics()
        setTimeout(() => {
          this.getDataFromApi()
        })
      },
      (err) => {
        EventBus.$emit('showAlert', 'warning', this.$t(err.response.data.message))
        EventBus.$emit('showLoading', false)
        this.$router.push({ name: 'voiceStats' })
      },
    )
    if (!this.$isSectionsBlockeds('costs')) {
      this.headers.splice(7, 0, {
        text: this.$t('Coste'),
        align: 'start',
        value: 'delivered_cost',
        class: 'w-6',
      })
    }
  },
  mounted () {
    this.globalControl.globals = false
      EventBus.$on('changedLanguage', () => {
      this.readyStats = false
      setTimeout(() => {
        this.setGraphicsData()
        this.readyStats = true
      })
    })
  },
}
