import CampaignVoiceInteractiveStats from '@/views/Pages/Stats/Voice/CampaignVoiceInteractiveStats.vue'
import CampaignVoiceBasicStats from '@/views/Pages/Stats/Voice/CampaignVoiceBasicStats.vue'

export default {
  name: 'VoiceStats',
  components: {
    CampaignVoiceInteractiveStats,
    CampaignVoiceBasicStats,
  },
  data: function () {
    return {
      campaignId: null,
      campaignType: null,
    }
  },

  created () {
    this.campaignId = +this.$route.params.id
    this.campaignType = this.$route.params.type
  },
}
